import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Home from "views/Home.js";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path='/home' component={Home} />
      <Redirect from='/' to='/home' />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
